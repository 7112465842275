import React, { useState } from "react";
import { graphql } from "gatsby";
// import Slider from "react-slick";
import Layout from "../Layout/Layout";
// import GatsbyImageCustom from "../Image/GatsbyImageCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannerImage from "../../assets/images/gallerHeader.png";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";

// const isBrowser = typeof window !== "undefined";

// function SampleNextArrow(props) {
//   const { onClick } = props;
//   return (
//     <div
//       className={`slick-arrow text-white hidden lg:flex absolute top-32 right-40 w-12 h-12 p-0 translate-x-0 -translate-y-1/2 cursor-pointer text-3xl  justify-center items-center`}
//       onClick={onClick}
//     >
//       <FontAwesomeIcon icon={faAngleRight} className="font-light" size="1x" />
//     </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { onClick } = props;
//   return (
//     <div
//       className={`slick-arrow text-white hidden lg:flex absolute top-32 left-40 w-12 h-12 p-0 translate-x-0 -translate-y-1/2 cursor-pointer text-3xl justify-center items-center z-50`}
//       onClick={onClick}
//     >
//       <FontAwesomeIcon icon={faAngleLeft} className="font-light" size="1x" />
//     </div>
//   );
// }

const GalleryContent = ({ data, location }) => {
  //usestate
  // const [slider1, setSlider1] = useState(null);
  // const [slider2, setSlider2] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAlbums, setIsAlbums] = useState();
  // console.log("Galary Data", isAlbums);

  // const settings = {
  //   className: "centered-slider ",

  //   infinite: true,
  //   // centerPadding: "60px",
  //   autoplay: false,
  //   speed: 5000,
  //   autoplaySpeed: 5000,
  //   cssEase: "linear",
  //   slidesToShow: 1,
  //   speed: 500,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  // };

  return (
    <>
      <Layout location={location} >
        <div>
          {/* banner */}
          <div className=" relative">
            <div className="bg-cover">
              <img
                src={BannerImage}
                style={{ height: "500px" }}
                alt="header"
                className=" w-full filter brightness-30 object-cover "
              />
              {/* <GatsbyImageCustom
                id={data.pagedata.cover_image.asset._id}
                className="filter brightness-50 lg:h-430 h-72  w-full"
              /> */}
            </div>
            <div className="mx-auto max-w-6xl">
              <div className=" absolute lg:top-60 top-52 right-0 left-0 bottom-0 ">
                <h1 className="font-bold lg:text-6xl md:text-4xl text-2xl text-white text-center">
                  {data.pagedata.meta_title || data.pagedata.title}
                </h1>
              </div>
              {/* //gallery */}

              <div className="py-20 lg:px-16 px-6 ">
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 ">
                  {/* {data.pagedata._rawGalleryImages &&
                    data.pagedata._rawGalleryImages.map((currEle) => {
                      // console.log(currEle, "currEle");
                      return (
                        <div
                          key={currEle._key}
                          className="p-2 cursor-pointer"
                          onClick={() => {
                            setIsOpen(!isOpen);
                            setIsAlbums(currEle.asset._ref);
                          }}
                          onKeyDown={() => {
                            setIsOpen(!isOpen);
                            setIsAlbums(currEle.asset._ref);
                          }}
                        >
                          <div className="overflow-hidden">
                            <GatsbyImageCustom
                              id={currEle.asset._ref}
                              alt="albums"
                              className="h-80 w-full object-cover transition transform ease-in-out hover:scale-110"
                            />
                          </div>
                        </div>
                      );
                    })} */}
                  {data.pagedata._rawGalleryImages &&
                    data.pagedata._rawGalleryImages.map((currEle, index) => {
                      let ImageData = data.allSanityImageAsset.edges.find(
                        (aa) => aa.node._id === currEle.asset._ref
                      );
                      // console.log('aaa',ImageData, currEle)

                      return (
                        <div className="relative" key={index}>
                          <div className="featured-imagebox featured-imagebox-portfolio">
                            <div className="featured-thumbnail">
                              <div>
                                <img
                                  className="img-fluid h-80 w-full object-cover"
                                  src={ImageData.node.url}
                                  alt="img"
                                />
                              </div>
                            </div>

                            <div className="ttm-box-view-overlay">
                              <div className="ttm-media-link">
                                <div
                                  className="ttm_prettyphoto ttm_image"
                                  data-gal="prettyPhoto[gallery1]"
                                  title={data.pagedata.title}
                                  // href={ImageData.node.url}
                                  onClick={() => {
                                    setIsOpen(true);
                                    setIsAlbums(ImageData.node.url);
                                  }}
                                  data-rel="prettyPhoto"
                                >
                                  <i className="ti ti-search">
                                    <FontAwesomeIcon
                                      icon={faSearch}
                                      size="1x"
                                    />
                                  </i>
                                </div>
                              </div>
                              {/* <div className="featured-content featured-content-portfolio">
                                <div className="featured-title">
                                  <h5>
                                    <a>Autue Art Gallery Opening</a>
                                  </h5>
                                </div>
                                <span className="category">
                                  <a>Private Party</a>
                                </span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* model */}

        {isOpen === true ? (
          // <div className="">
            <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-100">
            <div className="flex h-screen justify-center items-center">
              {/* <div> */}
                <img
                  src={isAlbums}
                  alt="img"
                  className="max-w-full h-auto md:h-screen m-auto"
                />
              {/* </div> */}
              <span className="absolute top-4 left-6">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="lg"
                  className="text-white cursor-pointer"
                  onClick={() => {
                    setIsOpen(false);
                    setIsAlbums();
                  }}
                />
              </span>
            </div>
          </div>
          // </div>
          
        ) : // <div className="fixed inset-0 bg-black bg-opacity-80">
        //   <div
        //     className="w-full h-screen overflow-auto"
        //     style={{ zIndex: 999 }}
        //   >
        //     {/* <div className=""> */}
        //       <div className="html5vid">
        //         {/* <div className=""> */}
        //
        //         {/* </div> */}
        //         <span className="absolute -top-12 -right-8">
        //           <FontAwesomeIcon
        //             icon={faTimes}
        //             size="lg"
        //             className="text-white cursor-pointer"
        //             onClick={() => {
        //               setIsOpen(false);
        //               setIsAlbums();
        //             }}
        //           />
        //         </span>
        //       </div>
        //     {/* </div> */}
        //   </div>
        // </div>
        null}
      </Layout>
    </>
  );
};

export default GalleryContent;

export const query = graphql`
  query GetGallaryPage($slug: String) {
    pagedata: sanityGallerypage(slug: { current: { eq: $slug } }) {
      meta_desc
      meta_title
      title
      weight
      slug {
        current
      }
      cover_image {
        asset {
          _id
        }
      }
      _rawGalleryImages
    }
    allSanityImageAsset {
      edges {
        node {
          _id
          url
        }
      }
    }
  }
`;
